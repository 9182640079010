import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import ordersService from '../../services/orders';

import EditNewOrder from '../edit/editNewOrder/EditNewOrder';
import ConfirmDialog from '../commons/ConfirmDialog';
import BackdropLoading from '../commons/BackdropLoading';
import DialogBase from './DialogBase';
import { DialogContent } from '@mui/material';

/**
 * À la différence de DialogEditOrder, qui est utilisé pour modifier une commande, ce composant gère
 * le tunnel pour la création d'une commande (paramétrage, choix des produits, fiche récapitulative)
 */
class DialogEditNewOrder extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      loading: false,
      order: null,
      openConfirm: false,
      confirmTitle: '',
      confirmMessage: '',
      confirmValid: 'Ok',
      confirmCancel: 'Annuler',
    }
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onClose () {
    const { order } = this.state;
    if (!order) {
      this._isMounted && this.setState({
        openConfirm: true,
        confirmTitle: "La commande n'est pas enregistrée",
        confirmMessage: "Vous allez perdre la commande en cours de création.",
        confirmValid: 'Je confirme',
      });
    } else if (order &&  ordersService.isStandard(order) && ordersService.isStateDraft(order)) {
      this._isMounted && this.setState({
        openConfirm: true,
        confirmTitle: "La commande n'est pas émise",
        confirmMessage: "Vous pouvez conserver cette commande en brouillon. Les brouillons sont automatiquement supprimés arrivés à échéance.",
        confirmValid: 'Conserver en brouillon',
        confirmCancel: 'Supprimer',
      });
    } else if (order &&  ordersService.isRecurrent(order) && !ordersService.isStatePending(order)) {
      this._isMounted && this.setState({
        openConfirm: true,
        confirmTitle: "La récurrence n'est pas activée",
        confirmMessage: "",
        confirmValid: 'Je confirme',
        confirmCancel: 'Annuler',
      });
    } else {
      this.onCloseConfirm();
    }
  }

  onCloseConfirm () {
    const { onClose } = this.props;
    onClose && onClose();
  }

  deleteDraftOrderAndClose () {
    const { onError } = this.props;
    const { order } = this.state;
    this._isMounted && this.setState({loading: true});
    ordersService.delete(order.ID).then(() => {
      this.onCloseConfirm();
    }).catch((e) => {
      onError && onError(e)
    }).finally(() => {
      this._isMounted && this.setState({loading: false});
    });
  }

  render () {
    const { classes, open, onClose, id, onSaved, ...others } = this.props;
    const { order, openConfirm, confirmTitle, confirmMessage, confirmValid, confirmCancel, loading } = this.state;
    const scrollableId = "dialog-edit-new-profile";
    return (
      <DialogBase
        maxWidth="sm"
        open={open}
        onClose={() => this.onClose()}
      >
        <DialogContent classes={{root: classes.dialogContent}} id={scrollableId}>
          { loading && (
            <BackdropLoading open={loading} />
          ) }
          { openConfirm && (
            <ConfirmDialog
              open={openConfirm}
              title={confirmTitle}
              message={confirmMessage}
              cancelLabel={confirmCancel}
              confirmLabel={confirmValid}
              onConfirm={ () => {
                this._isMounted && this.setState({openConfirm: false});
                this.onCloseConfirm();
              }}
              onCancel={ () => {
                this._isMounted && this.setState({openConfirm: false});
                if (this.state.order && ordersService.isStateDraft(this.state.order)) {
                  this.deleteDraftOrderAndClose();
                }
              }}
              onClose={ () => this._isMounted && this.setState({ openConfirm: false }) }
            />
          ) }
          <EditNewOrder
            scrollableTarget={scrollableId}
            hideTitle={true}
            order={ order }
            onSaved={ (order) => {
              this._isMounted && this.setState({order});
              onSaved && onSaved(order);
            }}
            onDeleted={ () => this.onCloseConfirm() }
            onQuit={ () => this.onCloseConfirm() }
            onEnd={ () => this.onCloseConfirm() }
            {...others}
          />
        </DialogContent>
      </DialogBase>
    )
  }
}

const styles = theme => ({
  dialogContent: {
    padding: 0,
  },
});

DialogEditNewOrder.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(DialogEditNewOrder);
