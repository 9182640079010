import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import EditNewDeliveryMode from '../edit/EditNewDeliveryMode';
import ConfirmDialog from '../commons/ConfirmDialog';
import Dialog from './Dialog';

class DialogEditNewDeliveryMode extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      openConfirm: false,
    }
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onClose () {
    this._isMounted && this.setState({ openConfirm: true });
  }

  onCloseConfirm () {
    const { onClose } = this.props;
    onClose && onClose();
  }

  render () {
    const { classes, open, fullScreen, keepMounted, id, ...others } = this.props;
    const { openConfirm } = this.state;
    return (
      <Dialog
        keepMounted={keepMounted}
        id={id}
        fullScreen={fullScreen}
        open={open}
        onClose={() => this.onClose()}
        title="Nouveau mode de livraison"
      >
        { openConfirm && (
          <ConfirmDialog
            open={openConfirm}
            title="Quitter le tunnel de création de mode de livraison ?"
            cancelLabel="Annuler"
            confirmLabel="Je confirme"
            onConfirm={ () => {
                this._isMounted && this.setState({openConfirm: false});
                this.onCloseConfirm();
              }
            }
            onClose={ () => this._isMounted && this.setState({ openConfirm: false }) }
          />
        ) }
        <EditNewDeliveryMode
          fixedCtrl={fullScreen}
          {...others}
        />
      </Dialog>
    )
  }
}

const styles = theme => ({
  paper_light_bg: {
    backgroundColor: theme.palette.bg.light,
  }
});

DialogEditNewDeliveryMode.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(DialogEditNewDeliveryMode);
