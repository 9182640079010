import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import EditGroup from './../edit/EditGroup';
import Dialog from './DialogBase';

class DialogEditGroup extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      title: '',
    }
  }

  componentDidMount () {
    this._isMounted = true;
    const { group } = this.props;
    if (group) {
      let title = "Modifier un groupe";
      this._isMounted && this.setState({
        title,
      });
    } else {
      this._isMounted && this.setState({
        title: "Nouveau groupe",
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render () {
    const { classes, open, onClose, fullScreen, keepMounted, ...others } = this.props;
    return (
      <Dialog
        keepMounted={keepMounted}
        fullScreen={fullScreen}
        open={open}
        onClose={onClose}
        title={this.state.title}
      >
        <EditGroup
          fixedCtrl={true}
          {...others}
        />
      </Dialog>
    )
  }
}

const styles = theme => ({});

DialogEditGroup.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(DialogEditGroup);
