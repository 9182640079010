import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import EditContact from '../edit/EditContact';
import { DialogContent, DialogTitle } from '@mui/material';
import DialogBase from './DialogBase';

class DialogEditContact extends React.Component {

  render () {
    const { classes, open, onClose, id_contact, ...others } = this.props;
    const scrollableId = "dialog-edit-contact";
    return (
      <DialogBase
        open={open}
        onClose={onClose}
      >
        <DialogTitle>{id_contact ? "Modification des coordonnées" : "Nouvelles coordonnées"}</DialogTitle>
        <DialogContent classes={{root: classes.dialogContent}} id={scrollableId}>
          <EditContact
            scrollableTarget={scrollableId}
            id_contact={id_contact}
            {...others}
          />
        </DialogContent>
      </DialogBase>
    )
  }
}

const styles = theme => ({
  dialogContent: {
    padding: theme.spacing(0),
  },
});

DialogEditContact.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(DialogEditContact);
