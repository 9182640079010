/**
 * Composant permettant d'ouvrir en popup l'profil
 - il peut prendre une commande 'order' en props (voir définition de ProfileCatalog)
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import ProfileCatalog from './../profile/ProfileCatalog';
import DialogBase from './DialogBase';
import { DialogContent, DialogTitle } from '@mui/material';

class DialogProfileCatalog extends React.Component {

  render () {
    const { classes, open, onClose, keepMounted, ...others } = this.props;
    const scrollableId = "dialog-profile-catalog";
    return (
      <DialogBase
        keepMounted={keepMounted}
        open={open}
        onClose={onClose}
      >
        <DialogTitle>Les produits</DialogTitle>
        <DialogContent classes={{root: classes.dialogContent}} id={scrollableId}>
          <ProfileCatalog
            scrollableTarget={scrollableId}
            hideTitle={true}
            {...others}
          />
        </DialogContent>
      </DialogBase>
    )
  }
}

const styles = theme => ({
  dialogContent: {
    padding: 0,
    backgroundColor: theme.palette.bg.light,
  },
});

DialogProfileCatalog.propTypes = {
  keepMounted: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

DialogProfileCatalog.defaultProps = {
  keepMounted: false,
};

export default withStyles(styles, { withTheme: true })(DialogProfileCatalog);
