import React from 'react';
import { withStyles } from '@mui/styles';
import profilesService from './../../services/profiles';

import { UserIcon } from './../commons/Icons';

import SelectAsyncMultiple from './SelectAsyncMultiple';
import ProfilesList from './../profile/ProfilesList';

/**
 * Sélecteur l'profils
 * Ce composant est asynchrone, il charge les valeurs à sélectionner depuis une API
 * Pour savoir quand le composant est initialisé, il faut écouter props.valueSelected
 */
class SelectProfiles extends React.Component {

  render () {
    const { classes, ...other } = this.props;
    return (
      <SelectAsyncMultiple
        { ...{...other, ...{
          title: this.props.title || "Profils",
          renderSelect: this.renderSelect,
          label: this.props.label || "Ajouter",
          label_icon: this.props.label_icon || <UserIcon />,
          renderSelected: (selected) => selected.name,
        }} }
        load={ (query, value, first_load) => profilesService.getAll({...query, include: value}) }
      />
    );
  }

  renderSelect = (onSelect, selectedItemsKeys, selected) => {
    return (
      <ProfilesList
        onSelect={onSelect}
        onError={this.props.onError}
        query={this.props.query}
        limit={25}
        selectedItemsKeys={selectedItemsKeys}
      />
    )
  }
}

const styles = theme => ({});

export default withStyles(styles, { withTheme: true })(SelectProfiles);
