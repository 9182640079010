import React from 'react';
import { withStyles } from '@mui/styles';
import profilesService from './../../services/profiles';

import { UserIcon } from './../commons/Icons';

import SelectAsync from './SelectAsync';
import ProfilesList from './../profile/ProfilesList';

/**
 * Sélecteur de fournisseur
 * Ce composant est asynchrone, il charge les valeurs à sélectionner depuis une API
 * Pour savoir quand le composant est initialisé, il faut écouter props.onInitialized
 */
class SelectProfile extends React.Component {

  render () {
    const { classes, ...other } = this.props;

    return (
      <SelectAsync
        { ...{...other, ...{
          renderSelect: this.renderSelect,
          label: this.props.label || "Profil",
          label_icon: !this.props.hide_icon ? (this.props.label_icon || <UserIcon />) : null,
          renderSelected: (selected) => selected.name,
        }} }
        load={(query, value) => profilesService.get(value)}
      />
    );
  }

  renderSelect = (onSelect, selectedItemKey, selected) => {
    return (
      <ProfilesList
        onSelect={onSelect}
        onError={this.props.onError}
        query={this.props.query}
        limit={25}
        selectedItemsKeys={[selectedItemKey]}
      />
    )
  }
}

const styles = theme => ({});

export default withStyles(styles, { withTheme: true })(SelectProfile);
