import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import complaintsService from '../../services/complaints';

import BackdropLoading from '../commons/BackdropLoading';
import EditControllers from '../commons/EditControllers';
import Form from '../commons/Form';
import FormFields from '../commons/FormFields';
import FormField from '../commons/FormField';
import { InfoIcon, PaperPlaneIcon } from '../commons/Icons';
import TextFieldLimited from '../commons/TextFieldLimited';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';

class EditNewComplaint extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      loading: false,
      data: {...{}, ...this.props.preset}
    }
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  showLoading() {
    this._isMounted && this.setState({loading: true})
  }

  hideLoading() {
    this._isMounted && this.setState({loading: false})
  }

  save (e) {
    e.preventDefault();
    this.showLoading();
    complaintsService.create(this.state.data).then(([id, notices]) => {
      notices && notices.length > 0 && this.props.onError(notices);
      this.props.onSaved(id);
    }).catch((error) => {
      console.error('error : ', error);
      this.props.onError(error);
    }).finally(() => {
      this.hideLoading();
    });
    return false;
  }

  render () {
    const { data } = this.state;
    const { classes, fixedCtrl } = this.props;
    return (
      <Box className={classes.container}>
        <BackdropLoading open={this.state.loading} />

        <Form onSubmit={ (e) => this.save(e) } noValidate>

          <FormFields>

            <FormField>
              <FormControl className={classes.form_control} fullWidth>
                <TextFieldLimited
                  id="description"
                  label="Description"
                  type="text"
                  name="description"
                  value={data.description || ''}
                  onChange={ (value) => this._isMounted && this.setState({data: {...data, ...{description: value}}}) }
                  multiline
                  rows={4}
                  limit={400}
                  className={classes.input}
                />
              </FormControl>
            </FormField>

            <FormField>
                <Box className={classes.info}>
                    <Box className={classes.info_icon}>
                        <InfoIcon color="primary" />
                    </Box>
                    <Typography className={classes.info_text} component="p" variant="body2">Ce signalement va être envoyé au service concerné et sera traité dans les plus brefs délais. Merci de participer au maintien d'un réseau de qualité.</Typography>
                </Box>
            </FormField>

            <EditControllers fixed={fixedCtrl}>
              <Button type="submit" className={classes.submit} onClick={ (e) => this.save(e) } color="primary" variant="contained" startIcon={<PaperPlaneIcon />}>
                Envoyer
              </Button>
            </EditControllers>

          </FormFields>

        </Form>

      </Box>
    )
  }
}

const styles = theme => ({
  container: {
    padding: theme.spacing(2),
  },
  form_control: {
    width: '100%',
  },
  input: {
    width: '100%',
  },
  submit: {
    width: '100%',
  },
  info: {
    width: '100%',
    display: 'flex',
  },
  info_icon: {
    marginRight: theme.spacing(1),
  },
  info_title: {
    width: '100%',
    fontWeight: 500,
    textDecoration: 'underline',
  },
  info_text: {
  },
});

EditNewComplaint.propTypes = {
  onSaved: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  fixedCtrl: PropTypes.bool,
  preset: PropTypes.object,
};

EditNewComplaint.defaultProps = {
  fixedCtrl: false,
  preset: {},
};

export default withStyles(styles, { withTheme: true })(EditNewComplaint);
