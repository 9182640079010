import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import EditNewProduct from '../edit/EditNewProduct';
import ConfirmDialog from '../commons/ConfirmDialog';
import DialogBase from './DialogBase';
import { DialogContent, DialogTitle } from '@mui/material';

class DialogEditNewProduct extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      openConfirm: false,
    }
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onClose () {
    this._isMounted && this.setState({ openConfirm: true });
  }

  onCloseConfirm () {
    const { onClose } = this.props;
    onClose && onClose();
  }

  render () {
    const { classes, open, ...others } = this.props;
    const { openConfirm } = this.state;
    const scrollableId = "dialog-edit-new-product";
    return (
      <DialogBase
        open={open}
        onClose={() => this.onClose()}
      >
        <DialogTitle>Nouveau produit</DialogTitle>
        <DialogContent classes={{root: classes.dialogContent}} id={scrollableId} >
          { openConfirm && (
            <ConfirmDialog
              open={openConfirm}
              title="Quitter le tunnel de création de produit ?"
              cancelLabel="Annuler"
              confirmLabel="Je confirme"
              onConfirm={ () => {
                  this._isMounted && this.setState({openConfirm: false});
                  this.onCloseConfirm();
                }
              }
              onClose={ () => this._isMounted && this.setState({ openConfirm: false }) }
            />
          ) }
          <EditNewProduct
              scrollableTarget={scrollableId}
            {...others}
          />
        </DialogContent>
      </DialogBase>
    )
  }
}

const styles = theme => ({
  dialogContent: {
    padding: theme.spacing(0),
  },
});

DialogEditNewProduct.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(DialogEditNewProduct);
