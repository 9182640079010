import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import EditInvitationCollaborate from '../edit/EditInvitationCollaborate';
import { DialogContent, DialogTitle } from '@mui/material';
import DialogBase from './DialogBase';

class DialogEditInvitationCollaborate extends React.Component {

  render () {
    const { classes, open, onClose, ...others } = this.props;
    const scrollableId = "dialog-edit-invitation-collaborate";
    return (
      <DialogBase
        open={open}
        onClose={onClose}
      >
        <DialogTitle>Invitation</DialogTitle>
        <DialogContent classes={{root: classes.dialogContent}} id={scrollableId}>
          <EditInvitationCollaborate
            scrollableTarget={scrollableId}
            {...others}
          />
        </DialogContent>
      </DialogBase>
    )
  }
}

const styles = theme => ({
  dialogContent: {
    padding: theme.spacing(0),
  },
});

DialogEditInvitationCollaborate.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(DialogEditInvitationCollaborate);
