import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import { DialogContent, DialogTitle } from '@mui/material';
import Auth from '../auth/Auth';
import DialogBase from './DialogBase';
import ViewContentLogo from '../main/ViewContentLogo';

class DialogAuth extends React.Component {

  render () {
    const { classes, open, onClose, keepMounted, ...others } = this.props;
    const scrollableId = "dialog-auth";
    return (
      <DialogBase
        maxWidth="xs"
        open={open}
        onClose={onClose}
        hideClose
      >
        <DialogTitle classes={{root: classes.dialogTitle}}>
          <ViewContentLogo className={classes.logo} />
        </DialogTitle>
        <DialogContent classes={{root: classes.dialogContent}} id={scrollableId}>
          <Auth
            scrollableTarget={scrollableId}
            hideTitle={true}
            {...others}
          />
        </DialogContent>
      </DialogBase>
    )
  }
}

const styles = theme => ({
  dialogTitle: {
    margin: 0,
  },
  dialogContent: {
    padding: theme.spacing(1, 0, 0, 0),
  },
});

DialogAuth.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(DialogAuth);
