import React from 'react';
import { withStyles } from '@mui/styles';
import groupsService from '../../services/groups';

import { StoreIcon } from './Icons';

import SelectAsyncMultiple from './SelectAsyncMultiple';
import GroupsList from '../group/GroupsList';

/**
 * Sélecteur de groupes de profils
 * Ce composant est asynchrone, il charge les valeurs à sélectionner depuis une API
 * Pour savoir quand le composant est initialisé, il faut écouter props.valueSelected
 */
class SelectGroups extends React.Component {

  render () {
    const { classes, ...other } = this.props;

    return (
      <SelectAsyncMultiple
        { ...{...other, ...{
          title: this.props.title || "Groupes de profils",
          renderSelect: this.renderSelect,
          label: this.props.label || "Ajouter",
          label_icon: this.props.label_icon || <StoreIcon />,
          renderSelected: (selected) => selected.name,
        }} }
        load={ (query, value, first_load) => groupsService.getAll({...query, include: value}) }
      />
    );
  }

  renderSelect = (onSelect, selectedItemsKeys, selected) => {
    return (
      <GroupsList
        onSelect={onSelect}
        onError={this.props.onError}
        query={this.props.query}
        canCreate={this.props.canCreate}
        limit={25}
        selectedItemsKeys={selectedItemsKeys}
      />
    )
  }
}

const styles = theme => ({});

export default withStyles(styles, { withTheme: true })(SelectGroups);
