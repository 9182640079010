import React from 'react';
import { withStyles } from '@mui/styles';
import attachmentsService from './../../services/attachments';

import imagePlaceholder from './../../static/img/placeholder.png';

import SelectAsync from './SelectAsync';
import AttachmentsList from './../attachment/AttachmentsList';
import { PictureIcon } from './../commons/Icons';

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

/**
 * Sélecteur d'utilisateur
 * Ce composant est asynchrone, il charge les valeurs à sélectionner depuis une API
 * Pour savoir quand le composant est initialisé, il faut écouter props.onInitialized
 */
class SelectAttachment extends React.Component {

  render () {
    const { classes, id_profile, ...other } = this.props;
    return (
      <SelectAsync
        { ...{...other, ...{
          renderSelect: this.renderSelect,
          label: this.props.label || "Image",
          label_icon: this.props.label_icon || <PictureIcon />,
          renderSelected: this.renderSelected,
        }} }
        load={(query, value) => attachmentsService.get(id_profile, value)}
      />
    );
  }

  renderSelected = (selected) => {
    const { classes } = this.props;
    
    const image = attachmentsService.get_url(selected, 'thumb', imagePlaceholder);
    return (
      <Box className={classes.thumb}>
        <Avatar className={classes.thumb_avatar} src={image} variant="square" alt="!" />
        <Typography className={classes.thumb_name} component="div" variant="body2">{ selected.name }</Typography>
      </Box>
    )
  }

  renderSelect = (onSelect, selectedItemKey, selected) => {
    const { id_profile } = this.props;
    return (
      <AttachmentsList
        id_profile={id_profile}
        onSelect={onSelect}
        onError={this.props.onError}
        query={this.props.query}
        limit={10}
        selectedItemsKeys={[selectedItemKey]}
        canCreate={true}
        selectAfterCreate={true}
      />
    )
  }
}

const styles = theme => ({
  thumb: {
    display: 'flex',
    alignItems: 'center',
  },
  thumb_avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  thumb_name: {
    marginLeft: theme.spacing(1),
    color: '#777777',
    wordBreak: 'break-all',
  }
});

export default withStyles(styles, { withTheme: true })(SelectAttachment);
