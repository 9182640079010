import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import EditOrder from './../edit/EditOrder';
import DialogBase from './DialogBase';
import { DialogContent, DialogTitle } from '@mui/material';

class DialogEditOrder extends React.Component {

  render () {
    const { classes, open, onClose, ...others } = this.props;
    const scrollableId = "dialog-edit-order";
    return (
      <DialogBase
        open={open}
        onClose={onClose}
        maxWidth="xs"
      >
        <DialogTitle>Paramètres de la commande</DialogTitle>
        <DialogContent classes={{root: classes.dialogContent}} id={scrollableId}>
          <EditOrder
            scrollableTarget={scrollableId}
            {...others}
          />
        </DialogContent>
      </DialogBase>
    )
  }
}

const styles = theme => ({
  dialogContent: {
    padding: theme.spacing(0),
  },
});

DialogEditOrder.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(DialogEditOrder);
